:root {
  --bg-color: #f1f3f7;
  --bg-color-100: #e1e5ee;
  --bg-color-200: #d1d7e4;
  --bg-color-dark-solid: #141414;
  --bg-color-black: #181818;
  --bg-gradient-black: linear-gradient(
    180deg,
    rgba(160, 160, 160, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bg-color-dark: hsla(0, 100%, 100%, 0.08);
  --bg-color-dark-active: hsla(0, 100%, 100%, 0.15);
  --color-primary: rgb(4, 6, 34);
  --color-main: #16b01e;
  --color-main-transparent-10: #16b01e1a;
  --color-main-transparent-20: #16b01e33;
  --color-secondary-dark: #a8a8ab;
  --white-semi: rgb(252, 252, 252);
  --white-dark: #c8c9d6;
  --box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.25),
    rgba(255, 255, 255, 0)
  );
  --bs-gradient-10: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0)
  );
  --bs-gradient-5: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.025),
    rgba(255, 255, 255, 0)
  );
  --bg-gradient: linear-gradient(180deg, #040622, #191b3b, #2e326e);
  --bg-gradient-gradial: radial-gradient(#070b3d, #191b3b, #2e326e);
  --box-shadow-dark: 0px 3px 4px 0px hsla(0, 0%, 0%, 0.14),
    0px 3px 3px -2px hsla(0, 0%, 0%, 0.12), 0px 1px 8px 0px hsla(0, 0%, 0%, 0.2);
  --border-color: #c0c2c5;
  --border-dark: 1px solid #2d2d2d;
  --sidebar-width-toggled: 90px;
  --font-color: #101113;
  --font-color-light: #606162;
  --color-expense: #ff423d;
  --color-expense-hover: #d73b36;
  --color-expense-active: #ab332f;
  --color-income: #16cb20;
  --color-income-hover: #199f1f;
  --color-income-active: #13961a;
  --color-main-active-row: #0f7815;
  --color-budget: #0066ff;
  --color-budget-hover: #035de4;
  --color-budget-active: #0351c6;
  --thead-bg-color-dark: #2d2d2d;
  --margin-gap-smallScreen: 1.25rem;
}

select,
input {
  -webkit-appearance: none;
  background-color: transparent;
}

html {
  scroll-behavior: smooth;
  scrollbar-width: thin;
  -ms-overflow-style: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: var(--bg-color);
  border-radius: 100vw;
}
::-webkit-scrollbar-thumb {
  background: var(--bg-color-100);
  border-radius: 100vw;
}

@supports (scrollbar-color: var(--bg-color) var(--bg-color-100)) {
  * {
    scrollbar-color: var(--bg-color) var(--bg-color-100);
    scrollbar-width: thin;
  }
}

@mixin mobile {
  @media (max-width: 650px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 1000px) {
    @content;
  }
}

@mixin smallScreen {
  @media (max-width: 1200px) {
    @content;
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(-20%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-right {
  0% {
    transform: translateX(-20%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-left {
  0% {
    transform: translateX(20%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
    scale: 0;
  }
  100% {
    opacity: 100%;
    scale: 1;
  }
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  color: var(--font-color);
  background-color: var(--bg-color);
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

button {
  cursor: pointer;
  transition: all 0.15s;
  line-height: 1.5;
  font-size: 0.875rem;
  font-family: inherit;
  border-radius: 0.75rem;
  padding: 0.47rem 0.75rem;
  border: none;
  text-align: center;
  vertical-align: middle;
  font-weight: 600;
}

.btn-primary {
  background-color: var(--color-main);
  color: var(--white-semi);
  border: 1px solid var(--color-main);
  box-sizing: border-box;

  &:hover {
    background-color: var(--color-income-hover);
    border-color: var(--color-income-hover);
  }
  &:active {
    background-color: var(--color-income-active);
  }
  &.active {
    background-color: var(--color-main);
  }

  &.inactive {
    background-color: var(--white-semi);
    color: var(--color-income-active);

    &:hover {
      background-color: var(--color-income-hover);
      color: var(--white-semi);
      border-color: var(--color-income-hover);
    }
  }
}

.btn-cancel {
  --tw-ring-offset-shadow: inset 0 0 0 0px #fff;
  --tw-ring-shadow: inset 0 0 0 1px rgb(209 213 219 / 1);
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  background-color: transparent;
  border: 1px solid var(--border-color);
  // box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
  //   var(--tw-shadow, 0 0 #0000);

  &:hover {
    background-color: rgb(249 250 251);
  }

  &:active {
    background-color: rgb(236, 237, 238);
  }
}

.expandToggleBtn {
  color: var(--font-color-light);
  background-color: var(--white-semi);
  border: 1px solid var(--border-color);
  transition: all 300ms;

  &.hidden {
    display: none;
  }
  &:hover {
    background-color: #f9f8f6;
    border: 1px solid var(--color-main-active-row);
    color: var(--color-main-active-row);
  }
  &:active {
    background-color: #efeeeb;
  }
}

input,
textarea,
select {
  font-family: inherit;
  font-size: 0.875rem;

  @include mobile {
    font-size: 1rem;
  }
}

.lightBorder {
  border: 1px solid var(--border-color);
  //   box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px inset,
  //     rgb(209, 213, 219) 0px 0px 0px 1px inset,
  //     rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

a {
  text-decoration: none;
  color: inherit;
}

.formContainer {
  max-height: 95vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.card {
  background-color: var(--white-semi);
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  padding: 1.25rem;
  font-size: 0.875rem;

  @include mobile {
    padding: 10px;
  }
}

.row {
  display: flex;
}

.overflowHidden {
  overflow: hidden;
}

.mainContent {
  margin-left: 255px;
  padding: 10px 20px;
  transition: all 0.15s;

  .content {
    max-width: 2000px;
    margin-inline: auto;
  }

  @include mobile {
    padding: 10px 5px;
    padding-bottom: 89px;
  }

  @media (display-mode: standalone) and (max-width: 650px) {
    padding-bottom: 89px;
  }

  .pageTitle {
    margin-top: 0;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.01em;
    color: #212529;

    @include mobile {
      margin-left: 10px;
    }
  }
}

footer {
  font-size: 0.75rem;
  color: var(--white-dark);
  text-align: center;
  padding: 30px 20px;
  box-sizing: border-box;

  a {
    text-decoration: underline;
  }
}
