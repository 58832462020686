@import "../../../index.scss";

.accountcard {
  box-sizing: border-box;
  box-shadow: var(--box-shadow);
  border-radius: 1rem;
  position: relative;
  width: 100%;
  max-height: 150px;
  transition: transform 0.4s cubic-bezier(0.36, 0.66, 0.04, 1);

  &:not(.disabled):hover {
    transform: scale(1.02);
    &:active {
      transform: scale(0.98);
    }
  }
  &.active {
    transform: scale(1.02);

    &::before,
    &::after {
      content: "";
      position: absolute;
      inset: -2px;
      z-index: -1;
      background: linear-gradient(121deg, #8884d8 0%, #6594d2 100%);
      border-radius: inherit;
    }

    &::after {
      filter: blur(0.3rem);
    }
  }

  .topCard {
    color: var(--white-semi);
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;
    padding: 0.5rem 1rem;
    background-image: var(--bs-gradient);

    .accountName {
      font-weight: 600;
      line-height: 1.2;
    }
  }

  .bottomCard {
    color: var(--font-color);
    padding: 1rem;
    background-color: #fff;
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
  }

  .accountBalance {
    font-size: 28px;
    font-weight: 500;

    .currency {
      font-size: 18px;
      font-weight: 400;
      margin-right: 0.35rem;
      text-transform: uppercase;
    }

    .cent {
      font-size: 18px;
      &.negative {
        color: red;
      }

      @include mobile {
        font-size: 0.75rem;
      }
    }
  }

  .accountActions {
    position: absolute;
    top: 0.35rem;
    right: 5px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    background: rgba($color: white, $alpha: 0.1);
    backdrop-filter: blur(20px);
    padding: 5px;
    border-radius: 10px;
    gap: 0.75rem;

    .actionContainer {
      padding: 5px;
      background: transparent;
      color: var(--color-income);
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.15s;
      border-radius: 5px;

      &:hover {
        background-color: var(--bg-color-dark-active);
      }
      &:active {
        background-color: #bebcbc;
      }

      .tooltip {
        display: flex;
        align-items: center;
      }
      .icon {
        font-size: 22px;
        filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.6));
      }
    }
  }
}
