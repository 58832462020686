.lazyloading{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logoContainer{
        border-radius: 10px;
        height: 50px;
        width: 50px;
        overflow: hidden;
        margin-block: 1rem;

        .logoImage{
            display: flex;
            width: 100%;
            object-fit: cover;
        }
    }
}