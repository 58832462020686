@import "../../../index.scss";

.accountslinecharts {
  border-radius: 10px;
  background-color: var(--white-semi);
  padding: 12px;
  box-shadow: var(--box-shadow);

  @include mobile {
    padding: 5px;
  }

  .summary {
    display: flex;
    justify-content: flex-end;

    .todaySummaryContainer {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      border-radius: 10px;
      padding: 10px;
      .label {
        margin-right: 10px;
        color: rgb(127, 131, 139);
        font-size: 0.875rem;
      }
      .amountToday {
        font-size: 1.3125rem;
        font-weight: 500;

        .currency {
          font-size: 1rem;
          margin-right: 0.35rem;
        }
        .cent {
          font-size: 1rem;

          &::before {
            content: ".";
          }
        }
      }
    }
  }
  .chartContainer {
    flex: 0 0 auto;
    width: 100%;
    height: 300px;

    @include mobile {
      height: 250px;
    }
  }

  .periodOptions {
    margin-top: 10px;
    box-sizing: border-box;
    display: flex;
    gap: 10px;
    justify-content: center;

    button {
      width: 50px;
      background-color: transparent;
      color: gray;
      font-size: 0.75rem;
      transition: all 300ms;

      &:hover {
        background-color: #e6e7e7;
      }
      &:active {
        background-color: #c9caca;
      }
      &.active {
        color: #212529;
        background-color: #e6e7e7;
      }
    }
  }
}
