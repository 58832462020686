@import "../../index.scss";

.accounts {
  .row {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  .overview {
    margin-bottom: 24px;
  }

  .actions {
    margin-left: auto;
    box-sizing: border-box;
    display: flex;
    gap: 10px;

    button {
      display: flex;
      align-items: center;
      animation: fade-in 0.15s;

      // &.save {
      //   background-color: #3b76e1;

      //   &:hover {
      //     background-color: #3265c5;
      //   }
      //   &:active {
      //     background-color: #2d5db5;
      //   }
      // }

      .icon {
        font-size: calc(1.25rem + 1px);
      }
    }
  }
  .accountsGroups {
    .divider {
      .groupName {
        padding-inline: 10px;
        font-size: 0.875rem;
        font-weight: 500;
      }

      hr {
        height: 1px;
        width: 100%;
        color: #8495ab;
        background-color: #8495ab;
        border: 0;
        opacity: 0.25;
      }
    }
    .accountsList {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(min(200px, 100%), 250px));
      grid-gap: 24px;
      margin-bottom: 24px;

      @include mobile {
        grid-template-columns: 1fr;
        padding-inline: 20px;
        grid-gap: 10px;
      }
    }
  }
}
