@import "../../index.scss";

.pftools {
  .pfToolsList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(350px, 100%), 1fr));

    grid-gap: 24px;
    margin-bottom: 24px;

    @include mobile {
      padding-inline: 10px;
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }

    .card {
      max-width: 450px;
      padding: 0;
      overflow: hidden;
    }

    .pfToolsItem {
      .top {
        padding: 1rem 1.25rem 0 1.25rem;

        .title {
          font-size: 1.15rem;
          font-weight: 600;
          line-height: 1.2;
        }
      }

      .bottom {
        padding: 1rem 1.25rem;

        .description {
          line-height: 1.5;
        }
      }
    }
  }
}
