@import "../index.scss";

.appMain.dark {
  color: var(--white-dark);

  .background {
    background: #000000;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    top: 0;
    height: 100vh;
    z-index: -99;
    position: fixed;
    background-image: linear-gradient(
      150deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 40%,
      rgba(22, 176, 30, 0.25) 50%,
      rgba(0, 0, 0, 1) 60%,
      rgba(0, 0, 0, 1) 100%
    );
  }

  .lightBorder {
    box-shadow: none;
  }
  .pageTitle {
    color: #d9e3f3;
  }
  ::-webkit-scrollbar-track {
    background: var(--bg-color-dark-solid);
  }
  ::-webkit-scrollbar-thumb {
    background: #283e5b;
    border-radius: 100vw;
  }

  @supports (scrollbar-color: var(--bg-color-dark-solid) #283e5b) {
    * {
      scrollbar-color: var(--bg-color-dark-solid) var(--bg-color-dark);
    }
  }

  input,
  select {
    color-scheme: dark;
  }

  select option {
    background-color: var(--bg-color-dark-solid);
  }

  button {
    &.inactive {
      background-color: transparent;
      color: var(--white-semi);

      &:hover {
        background-color: #2c4566;
        color: var(--white-semi);
      }
    }
  }

  .cancelButton {
    color: var(--white-semi) !important;
    background-color: transparent !important;
    border: var(--border-dark) !important;

    &:hover {
      background-color: var(--bg-color-dark) !important;
    }
  }

  .btn-cancel {
    color: var(--white-semi);
    background-color: transparent;

    &:hover {
      background-color: var(--bg-color-dark);
    }
  }

  .expandToggleBtn {
    color: var(--white-dark);
    background-color: var(--bg-color-dark);
    background-image: var(--bs-gradient-5);
    border: var(--border-dark);

    &:hover {
      background-image: var(--bs-gradient-10);
    }
    &:active {
      background-color: hsla(0, 100%, 100%, 0.15);
    }
  }

  .card {
    .cardTitle {
      color: var(--white-dark);
    }
    //background-color: var(--bg-color-dark-solid);
    background-color: rgba(#141414, 0.6);
    backdrop-filter: blur(40px);
    background-image: var(--bs-gradient-5);
    box-shadow: var(--box-shadow-dark);
    border: var(--border-dark);
  }

  .emojiIconContainer {
    background-color: var(--bg-color-dark);
    border: 1px solid rgba(21, 22, 23, 0.9);
  }

  .sidebar {
    background-color: var(--bg-color-dark-solid);
    border-right: var(--border-dark);
    box-sizing: border-box;

    .top {
      .logoLink {
        .logo {
          fill: white;
        }
      }

      .backIconContainer {
        color: var(--white-dark);
        background-color: var(--bg-color-dark);
        background-image: var(--bs-gradient-5);
        border: var(--border-dark);

        &:hover {
          background-image: var(--bs-gradient-10);
        }
        &:active {
          background-color: hsla(0, 100%, 100%, 0.15);
        }
      }
    }

    ul {
      .sub-menu-title {
        color: #a6acb8;
      }

      // .active {
      //   .sub-menu-item {
      //     color: var(--white-semi);
      //   }
      // }
      li {
        &.menu-title {
          color: #a6acb8;
        }
        &.menu-item {
          color: var(--color-secondary-dark);
        }
      }

      .sub-menu-item {
        color: #a6acb8;
        &:hover {
          color: var(--color-main);
        }
      }

      .addProject {
        color: #a6acb8;
      }

      .projectForm {
        color: #a6acb8;

        input {
          &::placeholder {
            color: #a6acb8;
          }
        }
      }
    }
  }

  .navbar {
    .item {
      color: #727e8f;
      .dropdownMenu {
        background-color: var(--bg-color-dark-solid);
        box-shadow: var(--box-shadow-dark);

        .currentUserInfo {
          border-bottom: var(--border-dark);
          .username {
            color: var(--white-dark);
          }
          .email {
            color: var(--color-secondary-dark);
          }
          .membershipType {
            color: #f5e54e;
            border-color: #f5e54e;
          }
        }
        .dropdownItem {
          color: var(--white-dark);

          &:hover {
            background-color: var(--bg-color-dark);
          }
        }
      }
    }
    .navbarContainer::before {
      background-image: none;
      // background-color: #040622;
      background-color: rgba(20, 20, 20, 0.2);
    }

    .settingsContainer {
      background-color: var(--bg-color-dark-solid);

      .settingsTitle {
        background-color: var(--thead-bg-color-dark);
        border-bottom: var(--border-dark);

        .closeIconContainer {
          background: var(--bg-color-dark-solid);
          background-image: var(--bs-gradient-5);

          &:hover {
            background-color: var(--bg-color-dark);
          }
          &:active {
            background-color: var(--bg-color-dark);
            background-image: var(--bs-gradient);
          }
        }
      }

      .title {
        color: var(--white-dark);
      }

      .settingItem {
        color: var(--white-dark);
      }
      .symbol {
        background-color: var(--bg-color-dark);
        color: var(--white-dark);
      }

      .selectContainer {
        box-shadow: var(--box-shadow-dark);
        border: 1px solid #343434;
        background-color: var(--bg-color-black);

        .selectItem {
          color: var(--color-secondary-dark);
          &:hover {
            background-color: var(--bg-color-dark);
          }
        }
      }
    }
  }

  .mobilenavbar {
    .mobileNavbarContainer {
      &::before {
        background-color: rgba(20, 20, 20, 0.2);
        background-image: none;
      }

      .navItems {
        .active {
          .navItem {
            color: var(--color-income);

            .active-pill {
              background-color: var(--color-income);
            }
          }
        }
        .navItem {
          color: var(--color-secondary-dark);
        }
      }
    }
  }

  .home {
    .optionsContainer {
      .buttonGroup {
        button {
          background-color: var(--thead-bg-color-dark);
          border: var(--border-dark);
          color: var(--white-semi);
          background-image: var(--bs-gradient-5);

          &:hover {
            background-image: var(--bs-gradient-10);
          }
          &:active {
            background-color: hsla(0, 100%, 100%, 0.15);
          }

          &.active {
            background-color: var(--color-income-active);
          }

          &.inactive {
            &:hover {
              background-color: var(--bg-color-dark);
              background-image: var(--bs-gradient-10);
            }
            &:active {
              background-color: hsla(0, 100%, 100%, 0.15);
            }
            background: transparent;
          }
        }
      }
    }

    .periodTitle {
      color: #d9e3f3;
    }

    .navigatePeriod {
      .dropdown {
        .dropdownMenu {
          background-color: var(--bg-color-dark-solid);
          border: var(--border-dark);

          .menuItem {
            color: var(--color-secondary-dark);

            &:hover {
              background-color: var(--bg-color-dark);
            }
          }
        }
      }
    }

    .widget,
    .barChart,
    .doughnutChart,
    .largeTable,
    .smallTable,
    .savingswidget,
    .sankeydiagram,
    .incomesources {
      //background-color: var(--bg-color-dark-solid);
      background-image: var(--bs-gradient-5);
      background-color: rgba(#141414, 0.6);
      backdrop-filter: blur(40px);
      box-shadow: var(--box-shadow-dark);
      border: var(--border-dark);

      .title {
        color: var(--white-dark);
      }

      .link {
        color: var(--white-semi);
      }
    }

    .widget,
    .savingswidget {
      background-image: var(--bs-gradient-10);
    }

    .savingswidget {
      .additionalInfo {
        .remaining {
          color: var(--color-secondary-dark);
          .remainingAmout {
            color: var(--white-dark);
            margin-right: 5px;
          }
        }
      }

      .right {
        .edit {
          color: var(--white-semi);
        }
      }
    }

    .doughnutChart {
      .lineBreak {
        color: #283f5c;
      }
      .customLegend {
        .legendItem {
          .label {
            color: var(--color-secondary-dark);
          }

          .label.active,
          .right.active {
            color: var(--white-semi);
          }
        }
      }
    }

    .largeTable {
      table {
        color: var(--white-dark);
        thead {
          border-bottom: 1px solid #414141;
          tr {
            color: var(--white-dark);
            background-color: var(--thead-bg-color-dark);

            :nth-child(1) {
              background-color: var(--thead-bg-color-dark);
            }
          }
        }
        tbody {
          tr {
            color: var(--color-secondary-dark);
            border-bottom: var(--border-dark);
            background-color: rgba(#121212, 1);

            :nth-child(1) {
              background-color: #121212;
            }

            @media (pointer: fine) {
              &:hover {
                background-color: var(--thead-bg-color-dark);

                td:nth-child(1) {
                  background-color: var(--thead-bg-color-dark);

                  .categoryLabelWrapper {
                    background-color: var(--thead-bg-color-dark);
                  }
                }
              }
            }

            &.active {
              td:not(:nth-child(1)) {
                background-color: var(--color-main-active-row);
                background-image: var(--bs-gradient);
              }
              td:nth-child(1) {
                background-color: var(--color-main-active-row);
                background-image: var(--bs-gradient);

                .categoryLabelWrapper {
                  background-color: var(--color-main-active-row);
                  background-image: var(--bs-gradient);
                }
              }
            }
          }
          td {
            border-bottom: var(--border-dark);
            &.categoryLabel {
              border-right: var(--border-dark);
            }
            .categoryLabelWrapper {
              color: var(--white-dark);
            }

            &.rightBorder {
              border-right: var(--border-dark);
            }

            &.total,
            &.average,
            &.budget,
            &.budgetLeft {
              color: var(--white-dark);
            }

            &.active {
              color: var(--white-dark);
            }
          }
        }
      }
    }

    .smallTable {
      .transactionssnapshot {
        .transactionList {
          .listItem {
            border: var(--border-dark);
            box-shadow: var(--box-shadow-dark);
            background-color: transparent;
            background-image: var(--bs-gradient-10);
            .info {
              .details {
                color: var(--color-secondary-dark);

                .vendor {
                  color: var(--white-dark) !important;
                }
              }
            }
          }
        }
      }
    }

    .incomesources {
      .categorytotals {
        .categoryContainer {
          box-shadow: var(--box-shadow-dark);
          border: var(--border-dark);
          background-image: var(--bs-gradient-10);
        }
      }
    }

    .toggleWrapper {
      .add-toggle {
        // background-color: #3b76e1;
        // background-image: var(--bs-gradient);
        box-shadow: var(--box-shadow-dark);

        &.absolute {
          .relativeParent {
            .label {
              color: #fff;
              text-shadow: 0.07em 0 black, 0 0.07em black, -0.07em 0 black,
                0 -0.07em black;
            }
          }
        }
      }
    }
  }

  .transactionsform,
  .projectform {
    .dateGroup {
      border: var(--border-dark);
    }

    .formContainer {
      background-color: var(--bg-color-dark-solid);
      background-image: var(--bs-gradient-10);
      border: var(--border-dark);

      .closeContainer {
        color: var(--white-dark);
        &:hover {
          background-color: var(--bg-color-dark);
        }
      }
      h1 {
        color: var(--white-dark);
      }

      .formInput {
        label {
          color: var(--color-secondary-dark);
        }
        input,
        select {
          color: var(--white-dark);
          border: var(--border-dark);
          background-color: var(--bg-color-dark);
          box-shadow: none;
        }
        .link {
          color: var(--color-main-active-row);
        }
      }

      .remainingBudget {
        .label {
          color: var(--color-secondary-dark);
        }

        .amount {
          color: var(--white-dark);
        }
      }
    }
  }

  .transactionsform {
    .formContainer {
      .projectAddRow {
        .projectsListContainer {
          box-shadow: var(--box-shadow-dark);
          background-color: var(--bg-color-dark-solid);
          border: var(--border-dark);

          .projectItem {
            &.noProject {
              color: var(--color-secondary-dark);
            }
            &:hover {
              background-color: var(--bg-color-dark);
            }
          }
        }
      }
      .projectAdd {
        color: var(--color-income-active);
        border: 1px solid var(--color-income-active);

        &:hover {
          background-color: var(--color-main);
          color: var(--white-semi);
        }
        &:active,
        &.active {
          border: 1px solid var(--color-income-active);
          background-color: var(--color-income-active);
          color: var(--white-semi);
        }
      }
    }
  }

  .transactions {
    .myProjectsButton {
      color: var(--color-income);
      border: 1px solid var(--color-income);
      background-color: var(--bs-gradient-10);

      &:hover {
        background-color: var(--color-main);
        color: var(--white-semi);
      }
      &:active {
        border: 1px solid var(--color-income-active);
        background-color: var(--color-income-active);
        color: var(--white-semi);
      }
    }
  }

  .datatable,
  .income,
  .accounttransfers {
    color: var(--white-dark);

    .topContent,
    .bottomContent {
      //background-color: var(--bg-color-dark-solid);
      background-color: rgba(#141414, 0.6);
      backdrop-filter: blur(40px);
      background-image: var(--bs-gradient-5);
      box-shadow: var(--box-shadow-dark);
      border: var(--border-dark);

      .datatableTitle {
        color: var(--white-dark);
      }
    }

    .topContent {
      .datatableInputs {
        .inputsContainer {
          .formInput {
            label {
              color: #8c9fb9 !important;
            }
            input,
            select {
              background-color: #253a55;
              border: var(--border-dark) !important;
              color: var(--white-dark);
            }
            .link {
              color: var(--color-main-active-row);
            }
          }
        }
      }
    }

    .bottomContent {
      background-image: none;
      box-shadow: none;
      .exportButton {
        color: var(--white-dark);
        background-color: var(--thead-bg-color-dark);
        border: var(--border-dark);
        background-image: var(--bs-gradient-5);

        &:hover {
          background-image: var(--bs-gradient-10);
        }
        &:active {
          background-color: hsla(0, 100%, 100%, 0.15);
        }
        &.inactive {
          &:hover {
            background-color: var(--bg-color-dark);
            background-image: var(--bs-gradient-10);
          }
          &:active {
            background-color: hsla(0, 100%, 100%, 0.15);
          }
          background: transparent;
        }
      }

      @include mobile {
        background-color: transparent;
        border: none;
      }

      .row {
        .datatableTotals {
          color: var(--white-dark);
        }
      }

      .datagridContainer {
        .datagrid {
          color: var(--white-dark);
          background-color: rgba(#121212, 0.7);

          .MuiDataGrid-columnHeaders {
            background-color: var(--thead-bg-color-dark);
          }
          .css-levciy-MuiTablePagination-displayedRows {
            color: var(--white-dark);
          }
          .MuiTablePagination-actions {
            button {
              color: var(--white-dark);
            }
          }
          .MuiDataGrid-cell {
            border-bottom: var(--border-dark);
          }
        }

        .descriptionCell {
          .project {
            color: var(--color-secondary-dark);
          }
        }
      }

      .add-toggle {
        box-shadow: var(--box-shadow-dark);
      }
    }

    .searchFilterInput {
      color: var(--white-dark);
      border: var(--border-dark);
      background-color: var(--bg-color-dark);
      background-image: var(--bs-gradient-5);
      box-shadow: none;
    }

    .dateGroup {
      .dateWrapper {
        .total {
          color: var(--color-secondary-dark);
        }
      }

      .dateGroupCard {
        border: var(--border-dark);
        background-color: var(--bg-color-dark-solid);
        background-image: var(--bs-gradient-5);

        li {
          .itemWrapper {
            .left {
              .info {
                .details {
                  color: var(--color-secondary-dark);

                  .vendor {
                    color: var(--white-dark);
                  }
                }
              }
            }
          }
        }
      }
    }

    .confirmDelete {
      color: var(--white-dark);

      .confirmDeleteContainer {
        border: var(--border-dark);
        background-color: var(--bg-color-dark-solid);
        h1 {
          color: var(--white-dark);
        }
      }
    }

    .formContainer {
      background-color: var(--bg-color-dark-solid);
      background-image: var(--bs-gradient-10);

      .closeContainer {
        color: var(--white-dark);
        &:hover {
          background-color: var(--bg-color-dark);
        }
      }
      h1 {
        color: var(--white-dark);
      }

      .formInput {
        label {
          color: var(--color-secondary-dark);
        }
        input,
        select {
          color: var(--white-dark);
          border: var(--border-dark);
          background-color: var(--bg-color-dark);
        }
      }

      .buttonsRow {
        .deleteButton {
          color: var(--white-semi) !important;
          background-color: transparent !important;
          border: var(--border-dark) !important;

          &:hover {
            background-color: var(--bg-color-dark) !important;
          }
        }
      }
    }
  }

  .editaddwidget {
    .formContainer {
      border: var(--border-dark);
      .projectAddRow {
        .projectsListContainer {
          box-shadow: var(--box-shadow-dark);
          background-color: var(--bg-color-dark-solid);
          border: var(--border-dark);

          .projectItem {
            &.noProject {
              color: var(--color-secondary-dark);
            }
            &:hover {
              background-color: var(--bg-color-dark);
            }
          }
        }
      }

      .projectAdd {
        color: var(--color-income-active);
        border: 1px solid var(--color-income-active);

        &:hover {
          background-color: var(--color-main);
          color: var(--white-semi);
        }
        &:active,
        &.active {
          border: 1px solid var(--color-income-active);
          background-color: var(--color-income-active);
          color: var(--white-semi);
        }
      }
      .formInput {
        .link {
          color: var(--color-main-active-row);
        }

        input,
        select {
          color: var(--white-dark);
          border: var(--border-dark);
          background-color: var(--bg-color-dark);
          box-shadow: none;
        }
      }

      .remainingBudget {
        .label {
          color: var(--color-secondary-dark);
        }

        .amount {
          color: var(--white-dark);
        }
      }
    }
  }

  .income {
    .gridContainer {
      .rightContainer {
        //background-color: var(--bg-color-dark-solid);
        background-color: rgba(#141414, 0.8);
        backdrop-filter: blur(40px);
        background-image: var(--bs-gradient-5);
        border: var(--border-dark);

        .lineBreak {
          color: #283f5c;
        }

        .totals {
          .totalSpent {
            color: var(--color-secondary-dark);
          }
        }

        .row {
          .category {
            color: var(--color-secondary-dark);
          }
        }
      }
    }

    .categorytotals {
      .categoryContainer {
        border: var(--border-dark);
        box-shadow: var(--box-shadow-dark);
        background-image: var(--bs-gradient-10);
      }
    }

    .optionsContainer {
      box-shadow: var(--box-shadow-dark);
      border: var(--border-dark);
      background-color: var(--bg-color-dark-solid);
      background-image: var(--bg-gradient-black);

      .periodTitle {
        color: var(--white-dark);
      }

      .navigatePeriod {
        .dropdownMenu {
          background-color: var(--bg-color-dark-solid);
          border: var(--border-dark);

          .menuItem {
            color: var(--color-secondary-dark);

            &:hover {
              background-color: var(--bg-color-dark);
            }
          }
        }
      }
    }
  }

  .projects {
    .projectsList {
      .projectItem {
        &:hover {
          background-color: var(--bg-color-dark);
        }
        &:active {
          background-color: hsla(0, 100%, 100%, 0.15);
        }

        .projectActions {
          button {
            color: var(--white-dark);

            &:hover {
              background-color: var(--bg-color-dark-active);
            }
          }
        }
      }
    }
  }
  .project {
    .actions {
      button {
        &:hover {
          background-color: var(--bg-color-dark);
        }
      }
    }

    .gridContainer {
      .rightContainer {
        //background-color: var(--bg-color-dark-solid);
        background-color: rgba(#141414, 0.8);
        backdrop-filter: blur(40px);
        background-image: var(--bs-gradient-5);
        border: var(--border-dark);

        .lineBreak {
          color: #283f5c;
        }
        .totals {
          .totalSpent {
            color: var(--color-secondary-dark);
          }
        }

        .row {
          .category {
            color: var(--color-secondary-dark);
          }
        }
      }
    }

    .categorytotals {
      .categoryContainer {
        border: var(--border-dark);
        box-shadow: var(--box-shadow-dark);
        background-image: var(--bs-gradient-10);
      }
    }
  }

  .categories {
    .categoryGroup {
      .categoriesList {
        .categoryItem {
          background-color: var(--bg-color-dark);

          &:hover {
            background-color: var(--bg-color-dark-active);
          }

          .categoryActions {
            .actionContainer {
              color: var(--white-dark);

              &:hover {
                background-color: var(--bg-color-dark-active);
              }
            }
          }
        }
      }
    }
  }

  .accountslinecharts {
    //background-color: var(--bg-color-dark-solid);
    background-color: rgba(#141414, 0.7);
    backdrop-filter: blur(40px);
    background-image: var(--bs-gradient-5);
    box-shadow: var(--box-shadow-dark);
    border: var(--border-dark);
    .summary {
      .todaySummaryContainer {
        .label {
          color: var(--white-dark);
        }
        .amountToday {
          color: var(--white-semi);
        }
      }
    }
    .periodOptions {
      button {
        &:hover {
          background-color: var(--bg-color-dark);
        }
        &:active {
          background-color: hsla(0, 100%, 100%, 0.15);
        }
        &.active {
          color: var(--white-dark);
          background-color: var(--bg-color-dark);
          background-image: var(--bs-gradient-10);
        }
      }
    }
  }

  .accountcard {
    box-shadow: var(--box-shadow-dark);
    border: var(--border-dark);
    &.active {
      .bottomCard {
        background-color: var(--bg-color-dark-solid);
        background-image: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.2),
          rgba(255, 255, 255, 0)
        );
      }
    }

    .bottomCard {
      color: var(--white-semi);
      background-color: var(--bg-color-dark);
      background-image: var(--bs-gradient-10);
    }
  }

  .accountform,
  .accounttransferform {
    .formContainer {
      border: var(--border-dark);
      background-color: var(--bg-color-dark-solid);
      background-image: var(--bg-gradient-black);

      .closeContainer {
        color: var(--white-dark);
        &:hover {
          background-color: var(--bg-color-dark);
        }
      }
      h1 {
        color: var(--white-dark);
      }

      .formInput {
        label {
          color: var(--color-secondary-dark);
        }
        input,
        select,
        textarea {
          color: var(--white-dark);
          border: var(--border-dark);
          background-color: var(--bg-color-dark);
        }
      }
    }
  }

  .accounttransferform {
    .transferFrom {
      color: var(--color-secondary-dark);
    }
  }

  .categoriesformmodal {
    .formContainer {
      border: var(--border-dark);
      background-color: var(--bg-color-dark-solid);
      background-image: var(--bs-gradient-10);

      .closeContainer {
        color: var(--white-dark);
        &:hover {
          background-color: var(--bg-color-dark);
        }
      }
      h1 {
        color: var(--white-dark);
      }

      .row {
        .applyToAllMonths {
          input {
            color: var(--white-dark);
            border: var(--border-dark);
            background-color: var(--bg-color-dark);
          }
        }
      }

      .formInput {
        label {
          color: var(--color-secondary-dark);
        }
        input,
        select {
          color: var(--white-dark);
          border: var(--border-dark);
          background-color: var(--bg-color-dark);
        }
      }
    }
  }

  .confirmdeletemodal {
    .confirmDeleteContainer {
      border: var(--border-dark);
      h3 {
        color: var(--white-dark);
      }
    }
  }

  .feedback {
    form {
      select,
      input,
      textarea {
        border: var(--border-dark);
        color: var(--color-secondary-dark);
        background-color: var(--bg-color-dark);
      }

      .note {
        color: var(--color-secondary-dark);
      }
    }
  }

  .colorpicker {
    .colorBox {
      &:hover,
      &.active {
        border: 2px solid var(--white-dark);
      }
    }
  }

  .iconpicker {
    background-color: var(--bg-color-dark);
    border: var(--border-dark);
    .iconsList {
      .iconContainer {
        &:hover,
        &.active {
          background-color: var(--bg-color-dark-active);
        }
      }
    }
  }

  .confirmDelete {
    color: var(--white-dark);
  }

  .confirmDeleteContainer {
    border: var(--border-dark);
    background-color: var(--bg-color-dark-solid);
    h1 {
      color: var(--white-dark);
    }
  }

  .budget {
    .left,
    .right {
      //background-color: var(--bg-color-dark-solid);
      background-color: rgba(#141414, 0.8);
      backdrop-filter: blur(40px);
      // background-image: var(--bg-gradient-black);
      box-shadow: var(--box-shadow-dark);
      border: var(--border-dark);

      .title {
        color: var(--white-dark);
      }
    }

    .left {
      .periodTitle.mobileOnly {
        color: var(--white-dark);
      }

      .optionsContainer {
        .buttonGroup {
          button {
            background-color: var(--thead-bg-color-dark);
            border: var(--border-dark);
            background-image: var(--bs-gradient-5);
            color: var(--white-semi);

            &:hover {
              background-image: var(--bs-gradient-10);
            }
            &:active {
              background-color: hsla(0, 100%, 100%, 0.15);
            }
            &.active {
              background: var(--color-income-active);
            }
            &.inactive {
              &:hover {
                background-color: var(--bg-color-dark);
                background-image: var(--bs-gradient-10);
              }
              &:active {
                background-color: hsla(0, 100%, 100%, 0.15);
              }
              background: transparent;
            }
          }
        }

        .periodTitle {
          color: var(--white-dark);
        }

        .navigatePeriod {
          .dropdown {
            .dropdownMenu {
              background-color: var(--bg-color-dark-solid);
              border: var(--border-dark);

              .menuItem {
                color: var(--color-secondary-dark);

                &:hover {
                  background-color: var(--bg-color-dark);
                }
              }
            }
          }
        }
      }

      .budgetprogress {
        border: var(--border-dark);
        background-color: var(--bg-color-dark);
        background-image: none;

        &:hover {
          background-color: var(--bg-color-dark-active);
        }
        &:active {
          background-color: hsla(0, 0%, 100%, 0.2);
        }

        .categoryTitle {
          .categoryName {
            color: var(--white-dark);
          }
        }

        .progress {
          background-color: var(--bg-color-dark-solid);
          //          background-color: var(--thead-bg-color-dark);
          border: var(--border-dark);
        }

        .amount {
          color: var(--white-dark);
        }

        .amountUsed {
          color: var(--color-secondary-dark);

          .totalSpent {
            color: var(--white-dark);
          }
        }
      }
    }
  }

  .budgetform {
    .formContainer {
      border: var(--border-dark);
      background-color: var(--bg-color-dark-solid);
      background-image: var(--bs-gradient-10);

      .closeContainer {
        color: var(--white-dark);
        &:hover {
          background-color: var(--bg-color-dark);
        }
      }
      h1 {
        color: var(--white-dark);
      }

      .row {
        .applyToAllMonths {
          input {
            color: var(--white-dark);
            border: var(--border-dark);
            background-color: var(--bg-color-dark);
          }

          .applyToAllMonthsButton {
            background-color: var(--bg-color-dark);
            color: var(--white-dark);

            &:hover {
              background-color: hsla(0, 100%, 100%, 0.15);
            }
            &:active {
              background-color: hsla(0, 100%, 100%, 0.3);
            }
          }
        }
      }

      .formInput,
      .notesInput {
        label {
          color: var(--color-secondary-dark);
        }
        input,
        select,
        textarea {
          color: var(--white-dark);
          border: var(--border-dark);
          background-color: var(--bg-color-dark);
        }
      }
    }
  }
  .subscription {
    hr {
      color: #2d2d2d;
      background-color: #2d2d2d;
    }

    .createNewContainer,
    .upgradeMembership {
      background-color: var(--bg-color-dark);
      box-shadow: var(--box-shadow-dark);
      border: var(--border-dark);
      span {
        color: var(--color-secondary-dark);
      }
    }

    .createNewSubscriptionWidget {
      background-color: var(--bg-color-dark-solid);
      background-image: var(--bs-gradient-10);
      box-shadow: var(--box-shadow-dark);
      border: var(--border-dark);

      input,
      textarea,
      select {
        border-bottom: var(--border-dark);
        color: var(--white-dark);

        &::placeholder {
          color: var(--color-secondary-dark);
        }
      }
      .description,
      select {
        border: var(--border-dark);
      }
      label {
        color: var(--white-dark);
      }

      .top {
        .title {
          .subscriptionIconContainer {
            .roundBackground {
              background-color: gray;
            }
          }
        }
      }

      .bottom {
        border-top: var(--border-dark);

        .note {
          color: var(--color-secondary-dark);
        }
      }
    }

    .subscriptionwidget {
      //background-color: var(--bg-color-dark-solid);
      background-color: rgba(#141414, 0.6);
      backdrop-filter: blur(40px);
      background-image: var(--bs-gradient-10);
      box-shadow: var(--box-shadow-dark);
      border: var(--border-dark);

      .top {
        .title {
          .subscriptionName {
            color: var(--white-dark);
          }
        }
      }

      .details {
        .subscriptionDetails {
          .subscriptionNotes {
            color: var(--color-secondary-dark);
          }

          .subscriptionCategory {
            border: var(--border-dark);
          }
        }
      }

      .bottom {
        color: var(--white-dark);
        border-top: var(--border-dark);

        .row {
          .frequency {
            color: var(--color-secondary-dark);
          }

          .startDate {
            color: var(--color-secondary-dark);
            span {
              color: var(--white-dark);
            }
          }

          .totalCost {
            .label {
              color: var(--color-secondary-dark);
            }
          }
        }
      }

      .optionsContainer {
        .relativeContainer {
          .dropdownOptions {
            background-color: rgba($color: white, $alpha: 0.1);
            backdrop-filter: blur(4px);
            .item {
              color: var(--white-dark);
              &:hover {
                background-color: var(--bg-color-dark);
              }
            }
          }
        }
        &:hover {
          background-color: var(--bg-color-dark);
        }
      }

      .description,
      select {
        color: var(--white-dark);
      }
    }

    .summarywidget {
      box-shadow: var(--box-shadow-dark);

      &.active {
        border: 2px solid #4f4f4f;

        .summaryContainer {
          background-color: rgba(#141414, 1);
        }
      }

      .summaryContainer {
        //background-color: var(--bg-color-dark-solid);
        background-color: rgba(#141414, 0.6);
        backdrop-filter: blur(40px);
        background-image: var(--bg-gradient-black);
        border: var(--border-dark);
      }

      .widgetTitle {
        color: var(--white-dark);
      }
    }

    .confirmDelete {
      color: var(--white-dark);
      .confirmDeleteContainer {
        border: var(--border-dark);
        background-color: var(--bg-color-dark-solid);
        h1 {
          color: var(--white-dark);
        }
      }
    }
  }

  .loancalculator,
  .firecalculator {
    .formInput {
      label {
        color: var(--white-dark) !important;
      }
      input,
      select {
        background-color: var(--bg-color-dark);
        border: var(--border-dark) !important;
        color: var(--white-dark);
      }
    }
  }
  .currentFilter {
    .monthFilter,
    .categoryFilter {
      border: var(--border-dark);

      .closeIconContainer {
        border: none;
        background-color: var(--bg-color-dark);
      }
    }
  }

  .amortizationTable,
  .fireresults {
    .tableContainer {
      table {
        thead {
          th {
            color: var(--white-dark);
          }
        }
        tbody {
          tr {
            &:nth-of-type(odd) {
              td {
                background-color: var(--bg-color-dark);
              }
            }
            &:hover {
              td {
                background-color: var(--bg-color-dark-active);
              }
            }

            td {
              color: var(--white-dark);
            }
          }
        }
      }
    }
  }

  .infoModal {
    .content {
      background-color: var(--bg-color-dark-solid);
      border: var(--border-dark);
      h1,
      p {
        color: var(--white-dark);
      }

      .closeContainer {
        color: var(--white-dark);
        &:hover {
          background-color: var(--bg-color-dark);
        }
      }
    }
  }

  .firecalculator {
    .addMoreNRE {
      color: var(--white-dark);
      background-color: var(--thead-bg-color-dark);
      border: var(--border-dark);
      background-image: var(--bs-gradient-5);

      &:hover {
        background-color: var(--thead-bg-color-dark);
        background-image: var(--bs-gradient-10);
      }
      &:active {
        background-color: hsla(0, 100%, 100%, 0.15);
      }
    }

    .formGroup {
      .futureNREGroup {
        .futureNREGroupTitle {
          .removeNREContainer {
            color: var(--white-dark);

            &:hover {
              background-color: var(--bg-color-dark);
            }
            &:active {
              background-color: var(--bg-color-dark);
              background-image: var(--bs-gradient);
            }
          }
        }
      }
    }
  }

  .editprofile {
    .accountInfo {
      background-color: var(--bg-color-dark-solid);
      background-image: var(--bs-gradient-5);
      box-shadow: var(--box-shadow-dark);

      .infoItem {
        .infoItemLabel {
          color: var(--color-secondary-dark);
        }

        .infoItemValue {
          form {
            input {
              background-color: var(--bg-color-dark);
              border: var(--border-dark);
            }
          }

          button {
            background-color: #2d4667;
            color: #fff;

            &:hover {
              background-color: #345279;
            }
            &:active {
              background-color: #3a5c87;
            }
          }

          .submitButton {
            background-color: transparent;

            .icon {
              color: var(--white-dark);
            }

            &:disabled {
              background-color: #2d46672b;
              .icon {
                color: #f0f0f02c;
              }
            }

            @include mobile {
              background-color: #2d4667;

              &:hover {
                background-color: #345279;
              }
              &:active {
                background-color: #3a5c87;
              }
            }
          }

          .cancelButton {
            background-color: transparent;

            @include mobile {
              background-color: darkgray;

              &:hover {
                background-color: rgb(201, 201, 201);
              }

              &:active {
                background-color: rgb(227, 226, 226);
              }
            }
          }

          .confirmDeleteAccount {
            background-color: rgb(213, 74, 74);
            color: white;

            &:hover {
              background-color: rgb(181, 63, 63);
            }
            &:active {
              background-color: rgb(160, 57, 57);
            }
          }

          .deleteAccountButton {
            background-color: transparent;
            color: rgb(218, 122, 122);

            &:hover {
              background-color: var(--bg-color-dark);
            }
          }
        }
      }
    }
  }

  .faqs {
    .faqsContainer {
      background-color: var(--bg-color-dark-solid);
      box-shadow: var(--box-shadow-dark);
      border: var(--border-dark);

      .faqsSegment {
        .faqsSegmentTitle {
          border: var(--border-dark);
          background: var(--thead-bg-color-dark);
          color: var(--white-semi);
          // background-image: var(--bs-gradient-5);
        }

        .faqsSegmentQuestions {
          .faqsSegmentItem {
            .faqsSegmentQuestion {
              color: var(--white-semi);
            }
          }
        }
      }
    }
  }

  .whatsnew {
    .whatsnewContainer {
      background-color: var(--bg-color-dark-solid);
      box-shadow: var(--box-shadow-dark);

      .featureDate {
        color: var(--color-secondary-dark);
      }
    }
  }
}
