@import "../../../index.scss";

.accounttransferform {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.507);
  display: flex;
  justify-content: center;
  align-items: center;

  .formContainer {
    background-color: #fff;
    box-shadow: var(--box-shadow-dark);
    padding: 0 60px;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mobile {
      padding: 0 30px;
    }

    .closeContainer {
      position: absolute;
      border-radius: 10px;
      right: 5px;
      top: 5px;
      padding: 5px;
      cursor: pointer;
      color: #545965;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.15s;

      &:hover {
        background-color: var(--bg-color-100);
      }
    }

    h1 {
      font-size: 20px;
      font-weight: 600;
      color: var(--font-color);
      text-align: center;
    }

    .errorMessage {
      color: red;
      font-size: 0.75rem;
      margin-bottom: 0.5rem;
    }

    .formInput {
      display: flex;
      flex-direction: column;
      width: 280px;

      label {
        display: flex;
        align-items: center;
        font-size: 0.75rem;
        color: gray;
        margin-bottom: 0.2rem;
      }

      input,
      select {
        padding: 15px;
        margin: 10px 0;
        border-radius: 0.75rem;
        color: var(--font-color);
        font-family: inherit;

        @include mobile {
          padding: 8px;
          border-radius: 8px;
        }
      }

      .amountField {
        position: relative;

        input {
          width: 100%;
          box-sizing: border-box;
          text-align: right;
          padding-left: 25px;
        }
        &::before {
          content: var(--currency-var, "");
          text-align: center;
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .submitRow {
      margin-block: 0.67em;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      gap: 1rem;

      @include mobile {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0;
        width: 100%;
      }

      button {
        margin: 0.67em 0;

        @include mobile {
          margin: 0.5rem 0;
        }
      }

      .submitButton {
        @include mobile {
          order: -1;
        }
      }

      .btn-cancel {
        @include mobile {
          width: 100%;
        }
      }
    }

    .transferFrom {
      padding: 10px;
      color: gray;
      font-weight: 500;
      font-size: 0.875rem;
    }
  }
}
