@import "../../index.scss";

.summarywidget {
  box-shadow: var(--box-shadow);
  border-radius: 1rem;
  transition: transform 0.4s cubic-bezier(0.36, 0.66, 0.04, 1);
  cursor: pointer;
  position: relative;

  &:hover {
    transform: scale(1.02);
  }
  &:active {
    transform: scale(0.98);
  }
  &.active {
    transform: scale(1.02);

    &::before,
    &::after {
      content: "";
      position: absolute;
      inset: -2px;
      z-index: -1;
      background: linear-gradient(121deg, #84d893 0%, #65d26b 100%);
      border-radius: inherit;
    }

    &::after {
      filter: blur(0.3rem);
    }
  }

  .summaryContainer {
    background: var(--white-semi);
    border-radius: inherit;
    padding: 10px;
  }

  .widgetTitle {
    font-weight: 600;
    font-size: 0.875rem;
    color: rgb(107 114 128);
    margin-bottom: 1rem;
  }

  .row {
    display: flex;
    align-items: flex-end;

    .amount {
      font-size: 28px;
      font-weight: 500;
      line-height: 1;
      margin-right: 5px;

      @include tablet {
        font-size: 22px;
      }

      .currency {
        font-size: 18px;
        font-weight: 400;
        margin-right: 0.35rem;
      }
    }
  }
}
