@import "../../index.scss";

.confirmDelete {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.507);
  justify-content: center;
  align-items: center;
  display: flex;
  color: var(--color-primary);

  .confirmDeleteContainer {
    background-color: #fff;
    padding: 0 60px;
    max-width: 600px;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: var(--box-shadow-dark);

    @include mobile {
      max-width: 80%;
      padding: 0 30px;
    }

    h1 {
      font-size: 20px;
      font-weight: 600;
      text-align: center;
    }

    p {
      margin-bottom: 2rem;
    }

    .row {
      display: flex;
      align-items: center;
      margin-bottom: 1em;
      box-sizing: border-box;
      gap: 1rem;

      @include mobile {
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
      }

      Button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.875rem;
        line-height: 1.5;
        text-align: center;
        white-space: nowrap;
        padding: 0.47rem 0.75rem;
        border-radius: 0.75rem;
        transition: all 0.15s;
        cursor: pointer;

        @include mobile {
          width: 100%;
        }
      }

      .deleteButton {
        color: var(--white-semi);
        background-color: var(--color-expense);

        @include mobile {
          order: -1;
        }

        &:hover {
          background-color: var(--color-expense-hover);
        }
        &:active {
          background-color: var(--color-expense-active);
        }

        .spinner {
          color: white;
        }
      }
    }
  }
}
