@import "../../index.scss";

.accounttransfers {
  .row {
    align-items: center;

    h1 {
      margin-bottom: 0;
    }
  }

  .bottomContent {
    padding: 20px;
    position: relative;
    background-color: var(--white-semi);
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    margin-top: 12px;
    @include mobile {
      padding: 10px 0;
      border-radius: 0;
      background-color: var(--bg-color);
    }

    .datatableTitle {
      width: 100%;
      font-size: 1rem;
      color: var(--font-color);
      font-weight: 600;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .topButtons {
      box-sizing: border-box;
      gap: 10px;
      display: flex;

      .add {
        display: flex;
        align-items: center;

        .icon {
          font-size: 0.875rem;
          margin-right: 0.2rem;
        }
      }
    }

    .datagridContainer {
      height: 80vh;
      width: 100%;
      border-radius: 10px;
      overflow: hidden;

      // @include tablet {
      //   height: 75vh;
      // }

      @include mobile {
        height: 80vh;
        display: none;
      }

      .datagrid {
        color: #101113;
        font-family: inherit !important;
        border: none;

        .MuiDataGrid-columnHeaderTitle {
          font-weight: 600 !important;
        }

        .MuiDataGrid-cell:focus-within {
          outline: none !important;
        }

        .cellWithImg {
          display: flex;
          align-items: center;

          .cellImg {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 20px;
          }
        }

        .cellWithColor {
          padding: 5px;
          border-radius: 5px;
          font-size: 0.75rem;
          display: flex;
          align-items: center;
          font-weight: 500;
        }

        .cellWithIcon {
          display: flex;
          align-items: center;

          .categoryIconContainer {
            display: flex;
            align-items: center;
            padding: 5px;
            margin-right: 0.375rem;
            border-radius: 8px;
            background-image: var(--bs-gradient);

            .icon {
              color: #fff;
              font-size: 18px;
            }
          }
        }

        .cellAction {
          display: flex;
          align-items: center;
          gap: 10px;

          @include smallScreen {
            gap: 0.5rem;
          }

          .editButton {
            padding: 2px 5px;
            border-radius: 5px;
            color: #3b76e1;
            border: 1px solid rgba($color: #3b76e1, $alpha: 0.596);
            cursor: pointer;
          }
          .deleteButton {
            padding: 2px 5px;
            border-radius: 5px;
            color: #f56e6e;
            border: 1px solid rgba($color: #f56e6e, $alpha: 0.6);
            cursor: pointer;
          }
        }

        .descriptionCell {
          .project {
            font-size: 0.75rem;
            font-weight: 500;
            color: #6b7280;
            display: flex;
            align-items: center;

            .projectIcon {
              height: 5px;
              width: 5px;
              border-radius: 50%;
              margin-right: 5px;
              background-color: rgb(184, 184, 184);
            }
          }
        }
      }
    }
  }

  .accounttransfermobile {
    list-style: none;

    // .searchFilterInput {
    //   margin: 10px 0;
    //   background-color: var(--white-semi);
    //   border-radius: 0.75rem;
    //   border: 1px solid var(--border-color);
    //   padding: 6px 0.75rem;
    //   border-radius: 8px;
    //   color: var(--font-color);
    //   box-sizing: border-box;
    //   width: 100%;
    // }

    .dateGroup {
      margin: 12px 0;

      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }

      .dateWrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.375rem;
        font-size: 1rem;
        font-weight: 500;
      }

      .dateGroupCard {
        background-color: var(--white-semi);
        // border: 1px solid var(--border-color);
        box-shadow: var(--box-shadow);
        border-radius: 10px;
        padding: 0.875rem 0.5rem;
        font-size: 0.75rem;

        li {
          cursor: pointer;
          transition: background-color 0.15s;
          border-radius: 8px;
          padding-block: 10px;

          &:not(:last-child) {
            margin-bottom: 1rem;
          }

          .itemWrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left {
              display: flex;
              align-items: center;

              .info {
                display: flex;
                align-items: center;

                .title {
                  font-size: 0.875rem;
                  font-weight: 500;
                  margin-bottom: 0.5rem;
                }

                .details {
                  margin-top: 0.3rem;
                  color: rgb(107 114 128);
                }
                .account {
                  padding: 5px;
                  margin-inline: 15px;
                  border-radius: 5px;
                  font-size: 0.75rem;
                  display: inline-block;
                  text-align: center;
                  margin-top: 0.3rem;
                  font-weight: 500;
                }

                .transferIcon {
                  display: flex;
                  align-items: center;
                }
              }
            }

            .right {
              display: flex;
              font-size: 0.875rem;
              font-weight: 500;

              .amount {
              }
            }
          }
        }
      }
    }
  }

  .add-toggle {
    position: absolute;
    bottom: 5.5%;
    left: 50%;
    transform: translateX(-50%);
    height: 3.5rem;
    width: 3.5rem;
    z-index: 800;
    background-color: var(--color-main);
    border: none;
    border-radius: 50%;
    outline: none;
    box-shadow: var(--box-shadow);
    cursor: pointer;
    align-items: center;
    justify-content: center;
    transition: all 400ms;
    display: none;

    @media (display-mode: standalone) and (max-width: 650px) {
      bottom: 12.5%;
    }

    &:hover {
      transform: translateX(-50%) scale(1.04);
    }

    &:active {
      transform: translateX(-50%) scale(0.96);
    }

    .icon {
      font-size: 1.75rem;
      color: var(--white-semi);
      filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.2));
    }

    @include mobile {
      display: flex;
      position: fixed;
      bottom: 12.5%;
    }
  }
}
