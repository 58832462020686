@import "../../index.scss";

.editaddwidget {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.507);
  display: flex;
  justify-content: center;
  align-items: center;

  .formContainer {
    background-color: #fff;
    box-shadow: var(--box-shadow-dark);
    padding: 0 60px;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mobile {
      padding: 0 30px;
    }

    .closeContainer {
      position: absolute;
      border-radius: 10px;
      right: 5px;
      top: 5px;
      padding: 5px;
      cursor: pointer;
      color: #545965;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.15s;

      &:hover {
        background-color: var(--bg-color-100);
      }
    }

    h1 {
      font-size: 20px;
      font-weight: 600;
      color: var(--font-color);
      text-align: center;
    }

    .projectAddRow {
      margin-bottom: calc(10px + 0.2rem);
      position: relative;
      justify-content: start;

      .projectsListContainer {
        position: absolute;
        left: 0;
        top: 30px;
        max-height: 400px;
        width: 100%;
        box-shadow: var(--box-shadow);
        box-sizing: border-box;
        overflow-y: scroll;
        padding: 0.5rem 0;
        background-color: #fff;
        border: 1px solid var(--border-color);
        border-radius: 1rem;
        -ms-overflow-style: none;
        cursor: default;
        z-index: 100001;
        -ms-overflow-style: none;
        -ms-overflow-style: none;
        scrollbar-width: none;

        .projectItem {
          position: relative;
          padding: 0.35rem;
          font-size: 0.875rem;
          font-weight: 400;

          &:hover {
            background-color: var(--bg-color-100);
          }

          &.noProject {
            color: gray;
          }
        }
      }
    }

    .projectAdd {
      background-color: transparent;
      border-radius: 0.625rem;
      padding: 2px 0.625rem;
      color: var(--color-main-active-row);
      border: 1px solid var(--color-main-active-row);
      font-size: 0.75rem;

      &:hover {
        border: 1px solid var(--color-main);
        background-color: var(--color-main);
        color: var(--white-semi);
      }

      &:active,
      &.active {
        border: 1px solid var(--color-income-active);
        background-color: var(--color-income-active);
        color: var(--white-semi);
      }
    }

    .formInput {
      display: flex;
      flex-direction: column;
      width: 280px;

      label {
        display: flex;
        align-items: center;
        font-size: 0.75rem;
        color: gray;
      }

      input,
      select {
        padding: 0.47rem 0.75rem;
        margin: 10px 0;
        border-radius: 0.75rem;
        background-color: rgba(233, 235, 247, 0.1);
        border: 1px solid var(--border-color);
        // box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px inset,
        //   rgb(209, 213, 219) 0px 0px 0px 1px inset,
        //   rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
        color: var(--font-color);
        font-family: inherit;

        @include mobile {
          padding: 6px 0.75rem;
          border-radius: 8px;
          margin: 8px 0;
        }
      }

      .amountField {
        position: relative;

        input {
          width: 100%;
          box-sizing: border-box;
          text-align: right;
          padding-left: 25px;
        }
        &::before {
          content: var(--currency-var, "");
          text-align: center;
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .link {
        position: relative;
        margin-left: 0.5rem;
        color: var(--color-main-active-row);
        font-weight: 500;
      }
    }

    .remainingBudget {
      display: flex;
      font-size: 0.75rem;

      .label {
        margin-left: auto;
        color: gray;
        margin-right: 5px;
      }

      .amount {
        font-weight: 600;
      }
    }

    .buttonsRow {
      margin-block: 0.67em;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      gap: 1rem;

      @include mobile {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0;
        width: 100%;
      }
    }

    .buttonWrapper {
      margin: 0.67em 0;

      &.submit {
        @include mobile {
          order: -1;
        }
      }

      @include mobile {
        margin: 0.5rem 0;
      }

      .buttonContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--white-semi);
        font-size: 0.875rem;
        line-height: 1.5;
        text-align: center;
        font-weight: 600;
        white-space: nowrap;
        border: 1px solid transparent;
        padding: 0.47rem 0.75rem;
        border-radius: 0.75rem;
        transition: all 0.15s;
        cursor: pointer;

        @include mobile {
          width: 100%;
        }

        &.expense {
          background-color: var(--color-expense);
          &:hover {
            background-color: var(--color-expense-hover);
          }
          &:active {
            background-color: var(--color-expense-active);
          }
        }

        &.income {
          background-color: var(--color-income);
          &:hover {
            background-color: var(--color-income-hover);
          }
          &:active {
            background-color: var(--color-income-active);
          }
        }

        .icon {
          font-size: 0.875rem;
          margin-right: 0.2rem;
        }

        .spinner,
        .icon {
          transition: all 0.3s;
          color: var(--white-semi);
        }
      }

      .btn-cancel {
        @include mobile {
          width: 100%;
        }
      }
    }
  }
}
