@import "../../index.scss";

.emojiIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--white-semi);
  border: 1px solid var(--border-color);
  padding: 0.75rem;
  width: 0.75rem;
  height: 0.75rem;

  &.size-md {
    width: 1rem;
    height: 1rem;
    padding: 0.75rem;
    .emojiIcon {
      font-size: 1.25rem;
    }
  }

  &.size-lg {
    width: 1.25rem;
    height: 1.25rem;
    padding: 1rem;
    .emojiIcon {
      font-size: 1.5rem;
    }
  }
}
