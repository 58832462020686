@import "../../index.scss";

.project {
  .row {
    display: flex;
    align-items: center;

    h1 {
      margin-bottom: 0;
    }

    .projectEditNameForm {
      display: flex;
      align-items: center;

      input {
        border-radius: 5px;
        border: var(--border-dark);
        font-size: 20px;
        padding: 3px 5px;
      }
    }

    .actions {
      margin-left: 10px;
      display: flex;

      button {
        padding: 2px;
        background-color: transparent;
        color: #545965;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.15s;

        &:hover {
          background-color: var(--bg-color-100);
        }
        &:active {
          background-color: var(--bg-color-200);
        }
      }

      .submitButton {
        color: var(--color-expense);

        &:disabled {
          color: rgb(199, 196, 196);
        }
      }
    }
  }

  .gridContainer {
    display: grid;
    grid-template-columns: 9fr 3fr;
    grid-gap: 24px;

    @include smallScreen {
      grid-template-columns: 1fr;
    }

    .rightContainer {
      margin-top: 12px;
      padding: 24px;
      background-color: var(--white-semi);
      border-radius: 10px;
      box-shadow: var(--box-shadow);

      @include smallScreen {
        order: -1;
      }

      @include mobile {
        padding: 10px;
      }

      .title {
        width: 100%;
        font-size: 1rem;
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 24px;
      }

      .totals {
        .totalSpent {
          color: rgb(107 114 128);
          font-size: 0.875rem;
          margin-bottom: 0.25rem;
        }

        .amountTotals {
          font-size: 1.75rem;
          font-weight: 600;

          .currency,
          .cent {
            font-size: 1.25rem;
            margin-right: 0.35rem;
          }
        }
      }

      .lineBreak {
        height: 1px;
        margin-bottom: 1.5rem;
        color: #8495ab;
        background-color: #8495ab;
        border: 0;
        opacity: 0.25;
      }

      .row {
        margin-bottom: 0.5rem;
        .category {
          color: rgb(107 114 128);
          font-size: 0.875rem;
          margin-bottom: 0.25rem;
        }

        .showCategoryToggle {
          margin-left: 20px;
          display: none;

          @include smallScreen {
            display: block;
          }
        }
      }
    }
  }
}
