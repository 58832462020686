@import "../../index.scss";

.subscriptionwidget {
  box-shadow: var(--box-shadow);
  background-color: var(--white-semi);
  border-radius: 1rem;
  position: relative;
  max-width: 600px;

  input,
  textarea,
  select {
    border: none;
    border-bottom: 1px solid lightgray;
    background-color: transparent;
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
  }

  .description,
  select {
    border: 1px solid lightgray;
    border-radius: 5px;
  }

  .top {
    padding: 1.25rem;

    .title {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      .subscriptionIconContainer {
        margin-right: 20px;
        img {
          object-fit: cover;
          height: 48px;
          width: 48px;
          border-radius: 50%;
        }
      }

      .subscriptionName {
        font-size: 1.25rem;
        color: rgb(20, 27, 43);
        font-weight: 600;
        line-height: 1.2;
      }

      .roundBackground {
        height: 48px;
        width: 48px;
        border-radius: 50%;
        background-color: #efefef;
        margin-right: 20px;
        position: relative;

        img {
          object-fit: cover;
          height: 48px;
          width: 48px;
          border-radius: 50%;
          // position: absolute;
        }
      }

      .inputsTop {
        display: flex;
        flex-direction: column;
        width: 80%;
        box-sizing: border-box;
        gap: 10px;
      }
    }

    .details {
      .subscriptionDetails {
        .subscriptionVendor {
          font-weight: 500;
          margin-bottom: 0.5rem;
        }
        .subscriptionNotes {
          font-size: 0.875rem;
          color: rgb(107 114 128);
          margin-bottom: 0.5rem;
        }

        .row {
          display: flex;
          gap: 10px;
          box-sizing: border-box;
          align-items: center;
        }

        .subscriptionAccount {
          padding: 5px;
          border-radius: 5px;
          font-size: 0.75rem;
          display: flex;
          align-items: center;
        }

        .subscriptionCategory {
          font-size: 0.75rem;
          padding: 5px 10px;
          border-radius: 0.75rem;
          border: 1px solid lightgray;
        }

        .description {
          margin: 10px 0;
        }
      }
    }
  }

  .bottom {
    border-top: 1px solid #eff0f2;
    padding: 0.5rem 1.25rem;
    display: flex;
    flex-direction: column;
    color: var(--font-color);
    font-weight: 500;
    font-size: 0.875rem;

    .currency {
      margin-right: 0.2rem;
    }

    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 0.25rem;

      .right {
        display: flex;
        align-items: end;
      }

      .frequency {
        font-size: 0.75rem;
        font-weight: 400;
        color: rgb(107 114 128);
      }

      .startDate {
        font-weight: 400;
        color: rgb(107 114 128);

        span {
          margin-left: 5px;
          color: var(--font-color);
        }
      }

      .totalCost {
        display: flex;
        align-items: end;

        .label {
          color: rgb(107 114 128);
          font-weight: 400;
          margin-left: 5px;
          font-size: 0.75rem;
        }
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    padding-bottom: 0.5rem;

    button {
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 3rem;
      width: 3rem;
      cursor: pointer;
      transition: all 0.15s;

      &:hover {
        transform: scale(1.04);
      }
      &:active {
        transform: scale(0.96);
      }
    }
    .submitButton {
      margin-right: 0.75rem;
      background-color: var(--color-main);
      color: white;
      &:hover {
        background-color: var(--color-income-hover);
      }
      &:active {
        background-color: var(--color-income-active);
      }

      .spinner {
        color: white;
      }
    }

    .cancelButton {
      color: gray;
    }
  }

  .optionsContainer {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    display: flex;
    align-items: center;
    color: rgb(107 114 128);
    padding: 0.2rem 0.375rem;
    border-radius: 0.5rem;
    transition: all 0.15s;
    cursor: pointer;

    .relativeContainer {
      position: relative;

      .dropdownOptions {
        box-sizing: border-box;
        position: absolute;
        right: 1rem;
        top: 0.875rem;
        background-color: #fff;
        box-shadow: var(--box-shadow);
        border-radius: 1rem;
        padding: 0.5rem 0;
        font-size: 0.875rem;
        overflow: hidden;
        display: none;
        animation: slide-up 0.15s;

        &.show {
          display: block;
        }

        .item {
          padding: 0.35rem 1.5rem;
          display: flex;
          align-items: center;

          &:hover {
            background-color: var(--bg-color-100);
          }

          .icon {
            font-size: 0.875rem;
          }

          span {
            margin-left: 10px;
          }
        }
      }
    }

    &:hover {
      background-color: var(--bg-color-100);
    }

    &:active {
      background-color: var(--bg-color-200);
    }
  }
}
