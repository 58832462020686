@import "../../index.scss";

.categorytotals {
  height: auto;
  max-height: 63vh;
  transition: height 0.15s;
  overflow-y: auto;

  @include smallScreen {
    height: 0;

    &.expanded {
      height: auto;
      max-height: 63vh;
    }
  }

  .emojiIconContainer {
    margin-right: 0.5rem;
  }

  .categoryContainer {
    border: 1px solid var(--border-color);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px;
    white-space: nowrap;
    margin-bottom: 10px;

    .categoryTitle {
      display: flex;
      align-items: center;
      width: 180px;
      overflow: hidden;

      @include mobile {
        width: 150px;
      }

      .categoryIconContainer {
        padding: 5px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        background-image: var(--bs-gradient);

        @include mobile {
          margin-right: 5px;
        }

        .icon {
          color: #fff;
          font-size: 18px;
        }
      }

      .categoryName {
        font-size: 0.875rem;
        font-weight: 500;
        flex: 1;

        @include mobile {
          font-size: 0.875rem;
        }
      }
    }

    .categoryAmount {
      flex: 1;
      justify-content: flex-end;
      min-width: 80px;
    }
    .amount {
      display: flex;
      justify-content: flex-end;
      font-size: 0.875rem;
      font-weight: 500;

      .currency {
        margin-right: 0.35rem;
      }

      @include mobile {
        font-size: 0.875rem;
      }
    }
  }
}
