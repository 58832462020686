.radialChart {
  .chartWrapper {
    width: 100%;
    display: flex;
    justify-content: center;

    .chartContainer {
      @media (max-width: 1100px) {
        max-width: 400px;
      }
      .radialChart {
        font-family: inherit !important;
      }
    }
  }

  .lineBreak {
    height: 1px;
    margin-bottom: 1.5rem;
    color: #8495ab;
    background-color: #8495ab;
    border: 0;
    opacity: 0.25;
  }

  .summaryItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    .title {
      white-space: nowrap;
      font-size: 0.875rem !important;
      margin: 0 !important;
      font-weight: 600;
    }

    .amount {
      font-size: 0.875rem;
      font-weight: 600;

      &.remainingBalance {
        color: var(--color-income);

        &.negative {
          color: var(--color-expense);
        }
      }
    }

    .remainingBalance {
      font-weight: 700;
    }
  }
}
