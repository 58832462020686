@import "../../index.scss";

.budgetprogress {
  border: 1px solid var(--border-color);
  background-color: var(--bg-color);
  background-image: var(--bs-gradient);
  border-radius: 10px;
  display: grid;
  grid-template-columns: 180px 3fr 1fr;
  justify-content: space-between;
  align-items: center;
  padding: 12px 10px;
  white-space: nowrap;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.4s cubic-bezier(0.36, 0.66, 0.04, 1);

  .row {
    width: 100%;
    box-sizing: border-box;
    justify-content: flex-end;
  }

  @include mobile {
    grid-template-columns: 180px 1fr;
  }

  &:hover {
    background-color: var(--color-main-transparent-10);
    color: var(--color-main-active-row);
  }
  &:active {
    background-color: var(--color-main-transparent-20);
  }

  .categoryTitle {
    display: flex;
    align-items: center;
    // width: 180px;
    overflow: hidden;

    @include mobile {
      width: 150px;
    }

    .emojiIconContainer {
      margin-right: 0.5rem;
    }

    .categoryIconContainer {
      padding: 5px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      height: 28px;
      width: 28px;
      box-sizing: border-box;
      background-image: var(--bs-gradient);

      @include mobile {
        margin-right: 5px;
      }

      .icon {
        color: #fff;
        font-size: 18px;
      }
    }

    .categoryName {
      font-size: 0.875rem;
      font-weight: 500;
      flex: 1;

      @include mobile {
        font-size: 0.875rem;
      }
    }
  }

  .progress {
    background-color: #e3e3e7;
    display: flex;
    height: 15px;
    padding: 4px;
    border-radius: 30px;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    // flex: 3;
    overflow: hidden;

    .progressBar {
      border-radius: 30px;
      position: absolute;
      background-color: var(--color-budget);
      background-image: var(--bs-gradient);
      top: 50%;
      left: 4px;
      height: 7px;
      transform: translateY(-50%);
      transition: width 400ms;

      &.negative {
        background-color: var(--color-expense);
      }

      &.nearLimit {
        background-color: #e5821d;
      }

      &::before {
        content: "";
        width: 4px;
        height: 4px;
        position: absolute;
        border-radius: 7px;
        right: 2px;
        top: 50%;
        transform: translateY(-50%);
        background-color: #fff;
      }
    }
  }

  .categoryAmount {
    // flex: 1;
    box-sizing: border-box;
    justify-content: flex-end;
    min-width: 120px;

    @include mobile {
      display: flex;
      margin-top: 5px;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      grid-column: span 2;
    }

    .amount,
    .amountUsed {
      display: flex;
      justify-content: flex-end;
      font-size: 0.875rem;
      font-weight: 500;

      @include mobile {
        font-size: 0.875rem;
      }

      .separator {
        box-sizing: border-box;
        margin: 0 0.3rem;
      }

      .amountLabel {
        margin-left: 5px;
      }
    }

    .amountUsed {
      box-sizing: border-box;
      font-size: 0.75rem;
      font-weight: 400;
      color: rgb(107 114 128);
      margin-top: 0.2rem;

      @include mobile {
        margin-left: 33px;
      }
      .label {
        margin-left: 5px;
      }

      .totalSpent,
      .totalBudget {
        box-sizing: border-box;
        display: flex;
      }

      .currency {
        margin-right: 0.2rem;
      }

      .totalSpent {
        font-weight: 500;
        color: var(--font-color);

        &.negative {
          color: var(--color-expense) !important;
        }
      }

      .setUp {
        &.withActualAmount {
          color: rgb(245, 110, 110);
        }
      }
    }
  }
}
