@import "../../index.scss";

.budgetform {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.507);
  display: flex;
  justify-content: center;
  align-items: center;

  .formContainer {
    background-color: #fff;
    box-shadow: var(--box-shadow-dark);
    padding: 0 60px;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablet {
      padding: 0 30px;
    }

    .closeContainer {
      position: absolute;
      border-radius: 10px;
      right: 5px;
      top: 5px;
      padding: 5px;
      cursor: pointer;
      color: #545965;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.15s;

      &:hover {
        background-color: var(--bg-color-100);
      }
    }

    h1 {
      font-size: 20px;
      font-weight: 600;
      color: var(--font-color);
      text-align: center;
    }

    .amountField {
      position: relative;

      input {
        box-sizing: border-box;
        padding-left: 20px !important;
      }

      &::before {
        content: var(--currency-var, "");
        text-align: center;
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      @include mobile {
        flex-direction: column-reverse;
      }

      .applyToAllMonths {
        display: flex;
        align-items: center;

        @include mobile {
          margin-top: 10px;
        }

        input {
          box-sizing: border-box;
          padding: 0.47rem 0.75rem;
          margin: 10px 0;
          border-radius: 0.35rem;
          color: var(--font-color);
          background-color: rgba(233, 235, 247, 0.1);
          font-family: inherit;
          text-align: right;

          @include mobile {
            margin: 0 0 5px 0;
            padding: 6px 7px;
            max-width: 150px;
          }
        }
        .applyToAllMonthsButton {
          display: flex;
          align-items: center;
          margin-left: 10px;
          font-weight: 400;
          font-size: 0.75rem;
          background-color: var(--bg-color);
          transition: all 300ms;

          &:hover {
            background-color: var(--bg-color-100);
          }
          &:active {
            background-color: var(--bg-color-200);
          }

          .icon {
            margin-left: 5px;
            font-size: 0.75rem;
          }
        }
      }
    }

    .totalCounter {
      display: flex;
      font-weight: 500;

      div {
        margin-right: 1rem;
      }
    }

    form {
      display: grid;

      .monthsGroupInput {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 0 20px;

        @include mobile {
          grid-template-columns: 1fr;
          justify-items: end;
          max-height: 50vh;
          overflow-y: scroll;
          padding-block: 10px;
          border-top: 1px solid var(--bg-color-200);
          border-bottom: 1px solid var(--bg-color-200);

          input {
            max-width: 180px;
          }
        }
      }

      .formInput {
        display: flex;
        flex-direction: column;

        @include mobile {
          flex-direction: row;
        }

        label {
          display: flex;
          align-items: center;
          font-size: 0.75rem;
          color: gray;
          text-transform: capitalize;

          @include mobile {
            margin-right: 10px;
          }
        }

        input,
        select {
          box-sizing: border-box;
          padding: 0.47rem 0.75rem;
          margin: 10px 0;
          border-radius: 0.35rem;
          color: var(--font-color);
          background-color: rgba(233, 235, 247, 0.1);
          font-family: inherit;
          text-align: right;

          @include mobile {
            margin: 0 0 5px 0;
            padding: 6px 7px;
          }
        }
      }

      .notesInput {
        width: 100%;
        margin-top: 10px;
        display: flex;
        flex-direction: column;

        label {
          display: flex;
          align-items: center;
          font-size: 0.75rem;
          color: gray;
          text-transform: capitalize;

          @include mobile {
            margin-right: 10px;
          }
        }

        textarea {
          box-sizing: border-box;
          padding: 0.47rem 0.75rem;
          margin: 10px 0;
          border-radius: 0.35rem;
          color: var(--font-color);
          background-color: rgba(233, 235, 247, 0.1);
          font-family: inherit;

          @include mobile {
            margin: 5px 0;
            padding: 8px 7px;
          }
        }
      }
    }

    .buttonWrapper {
      margin: 1em 0;

      @include mobile {
        width: 100%;
      }

      .buttonContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--color-budget);
        color: var(--white-semi);
        font-size: 0.875rem;
        line-height: 1.5;
        text-align: center;
        font-weight: 600;
        white-space: nowrap;
        border: 1px solid transparent;
        padding: 0.47rem 0.75rem;
        border-radius: 0.75rem;
        transition: all 0.15s;
        cursor: pointer;

        @include mobile {
          width: 100%;
        }

        &:hover {
          background-color: var(--color-budget-hover);
        }
        &:active {
          background-color: var(--color-budget-active);
        }

        .icon {
          font-size: 0.875rem;
          margin-right: 0.2rem;
        }

        .spinner,
        .icon {
          transition: all 0.3s;
          color: var(--white-semi);
        }
      }
    }
  }
}
