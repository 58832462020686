@import "../../index.scss";
.transactions {
  .row {
    align-items: center;

    h1 {
      margin-bottom: 0;
    }
  }

  .myProjectsButton {
    border-radius: 0.625rem;
    margin-left: 1rem;
    font-size: 0.875rem;
    padding: 0.275rem 0.75rem;
    display: flex;
    align-items: center;
    margin-left: auto;
    border: 1px solid var(--color-main-active-row);
    color: var(--color-main-active-row);
    background-color: white;
    font-weight: 600;

    &:hover {
      border: 1px solid var(--color-main);
      background-color: var(--color-main);
      color: var(--white-semi);
    }

    &:active {
      border: 1px solid var(--color-income-active);
      background-color: var(--color-income-active);
      color: var(--white-semi);
    }

    @include mobile {
      margin-right: 10px;
    }

    .icon {
      font-size: 1rem;
      margin-right: 5px;
    }
  }
}
