.errorfallback {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: rgb(246, 249, 255);

  .logoContainer {
    border-radius: 10px;
    height: 50px;
    width: 50px;
    overflow: hidden;
    margin-block: 1rem;

    .logoImage {
      display: flex;
      width: 100%;
      object-fit: cover;
    }
  }

  h1 {
    font-size: 100px;
    font-weight: 600;
    margin: 0;
    color: #4154f1;
  }

  h2 {
    color: var(--font-color);
    font-weight: 500;
    font-size: 21px;
  }

  button {
    background-color: #3b76e1;
    color: #fff;
    font-weight: 400;
    font-size: 0.875rem;
    font-family: inherit;
    line-height: 1.5;
    text-align: center;
    vertical-align: middle;
    padding: 0.47rem 0.75rem;
    border: none;
    border-radius: 0.75rem;
    transition: all 300ms;

    &:hover {
      background-color: #366acb;
    }
    &:active {
      background-color: #2e5db5;
    }
  }
}
