@import "../../index.scss";

.datatable {
  .currency {
    margin-right: 0.35rem;
  }

  .topContent,
  .bottomContent {
    padding: 20px;
    position: relative;
    background-color: var(--white-semi);
    border-radius: 10px;
    box-shadow: var(--box-shadow);

    .datatableTitle {
      width: 100%;
      font-size: 1rem;
      color: var(--font-color);
      font-weight: 600;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  // .topContent {
  //   padding: 10px 20px;
  //   margin: 12px 0 24px 0;

  //   @include tablet {
  //     display: none;
  //   }

  //   .datatableInputs {
  //     width: 100%;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;

  //     .inputsContainer {
  //       display: flex;
  //       justify-content: space-between;

  //       form {
  //         display: flex;
  //         gap: 10px;
  //         margin-bottom: 0.5rem;
  //         margin-right: 20px;

  //         .formInput {
  //           label {
  //             display: flex;
  //             align-items: center;
  //             font-size: 0.75rem;
  //             color: gray;
  //             margin-bottom: 0.2rem;
  //           }

  //           input,
  //           select {
  //             width: 100%;
  //             box-sizing: border-box;
  //             padding: 0.47rem 0.75rem;
  //             border: 1px solid lightgray;
  //             color: #545965;
  //             background-color: rgba(233, 235, 247, 0.25);
  //             border-radius: 0.75rem;
  //             font-family: inherit;
  //           }

  //           .amountField {
  //             position: relative;

  //             input {
  //               width: 100%;
  //               box-sizing: border-box;
  //               text-align: right;
  //               padding-left: 25px;
  //             }
  //             &::before {
  //               content: var(--currency-var, "");
  //               text-align: center;
  //               position: absolute;
  //               left: 10px;
  //               top: 50%;
  //               transform: translateY(-50%);
  //             }
  //           }

  //           .link {
  //             position: relative;
  //             margin-left: 0.5rem;
  //             color: var(--bg-color-dark-solid);
  //             font-weight: 500;
  //           }
  //         }

  //         .dateInput {
  //           width: 120px;
  //         }
  //       }
  //     }
  //   }
  // }

  .topButtons {
    box-sizing: border-box;
    gap: 10px;
    display: flex;
  }

  .buttonContainer {
    display: flex;
    align-items: center;
    background-color: var(--color-expense);
    color: var(--white-semi);
    font-size: 0.875rem;
    line-height: 1.5;
    text-align: center;
    font-weight: 600;
    white-space: nowrap;
    border: 1px solid transparent;
    box-shadow: var(--box-shadow);
    padding: 0.47rem 0.75rem;
    border-radius: 0.75rem;
    transition: all 300ms;
    cursor: pointer;

    @include mobile {
      display: none;
    }

    &:hover {
      background-color: var(--color-expense-hover);
    }
    &:active {
      background-color: var(--color-expense-active);
    }

    .icon {
      font-size: 0.875rem;
      margin-right: 0.2rem;
    }

    .spinner,
    .icon {
      transition: all 0.3s;
      color: var(--white-semi);
    }
  }
  .bottomContent {
    margin-top: 12px;
    @include mobile {
      padding: 10px 0;
      border-radius: 0;
      background-color: var(--bg-color);
    }

    .exportButton {
      padding: 0.47rem 0.75rem;
      border-radius: 10px;
      letter-spacing: 0.08em;
      display: flex;
      align-items: center;
      font-weight: normal;
      border: 1px solid #d8dade;
      background-color: var(--bg-color);
      transition: all 300ms;

      &:hover {
        background-color: var(--bg-color-100);
      }
      &:active {
        background-color: var(--bg-color-200);
      }

      .icon {
        margin-right: 6px;
        font-size: 18px;
      }
    }

    .row {
      display: flex;
      justify-content: end;
      align-items: center;

      .datatableTotals {
        display: flex;
        white-space: nowrap;
        color: var(--font-color);
        font-weight: 500;
        font-size: 0.875rem;

        .description {
          margin: 0;
        }

        .amountTotal {
          margin-left: 10px;
        }
      }
    }

    .datagridContainer {
      height: 80vh;
      width: 100%;
      border-radius: 10px;
      overflow: hidden;

      // @include tablet {
      //   height: 75vh;
      // }

      @include mobile {
        height: 80vh;
        display: none;
      }

      .datagrid {
        color: #101113;
        font-family: inherit !important;
        border: none;

        .MuiDataGrid-columnHeaderTitle {
          font-weight: 600 !important;
        }

        .MuiDataGrid-cell:focus-within {
          outline: none !important;
        }

        .cellWithImg {
          display: flex;
          align-items: center;

          .cellImg {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 20px;
          }
        }

        .cellWithColor {
          padding: 5px;
          border-radius: 5px;
          font-size: 0.75rem;
          display: flex;
          align-items: center;
          font-weight: 500;
        }

        .cellWithIcon {
          display: flex;
          align-items: center;

          .emojiIconContainer {
            margin-right: 0.5rem;
          }

          .categoryIconContainer {
            display: flex;
            align-items: center;
            padding: 5px;
            margin-right: 0.375rem;
            border-radius: 8px;
            background-image: var(--bs-gradient);

            .icon {
              color: #fff;
              font-size: 18px;
            }
          }
        }

        .cellAction {
          display: flex;
          align-items: center;
          gap: 10px;

          @include smallScreen {
            gap: 0.5rem;
          }

          .editButton {
            padding: 2px 5px;
            border-radius: 5px;
            color: #3b76e1;
            border: 1px solid rgba($color: #3b76e1, $alpha: 0.596);
            cursor: pointer;
          }
          .deleteButton {
            padding: 2px 5px;
            border-radius: 5px;
            color: #f56e6e;
            border: 1px solid rgba($color: #f56e6e, $alpha: 0.6);
            cursor: pointer;
          }
        }

        .descriptionCell {
          .project {
            font-size: 0.75rem;
            font-weight: 500;
            color: #6b7280;
            display: flex;
            align-items: center;

            .projectIcon {
              height: 5px;
              width: 5px;
              border-radius: 50%;
              margin-right: 5px;
              background-color: rgb(184, 184, 184);
            }
          }
        }
      }
    }
  }
  .add-toggle {
    position: absolute;
    bottom: 5.5%;
    left: 50%;
    transform: translateX(-50%);
    height: 3.5rem;
    width: 3.5rem;
    z-index: 800;
    background-color: var(--color-expense);
    border: none;
    border-radius: 50%;
    outline: none;
    box-shadow: var(--box-shadow);
    cursor: pointer;
    align-items: center;
    justify-content: center;
    transition: all 400ms;
    display: none;

    @media (display-mode: standalone) and (max-width: 650px) {
      bottom: 12.5%;
    }

    &:hover {
      transform: translateX(-50%) scale(1.04);
    }

    &:active {
      transform: translateX(-50%) scale(0.96);
    }

    .icon {
      font-size: 1.75rem;
      color: var(--white-semi);
      filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.2));
    }

    @include mobile {
      display: flex;
      position: fixed;
      bottom: 12.5%;
    }
  }

  .confirmDelete {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.507);
    justify-content: center;
    align-items: center;
    display: flex;

    .confirmDeleteContainer {
      background-color: #fff;
      padding: 0 60px;
      border-radius: 10px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-shadow: var(--box-shadow-dark);

      @include mobile {
        padding: 0 30px;
      }

      h1 {
        font-size: 20px;
        font-weight: 500;
        color: var(--font-color);
        text-align: center;
      }

      .row {
        display: flex;
        align-items: center;
        margin-bottom: 0.67em;
        box-sizing: border-box;
        gap: 1rem;

        @include mobile {
          display: grid;
          grid-template-columns: 1fr;
          margin-bottom: 1.25rem;
          width: 100%;
        }

        Button {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.875rem;
          line-height: 1.5;
          text-align: center;
          white-space: nowrap;
          padding: 0.47rem 0.75rem;
          border-radius: 0.75rem;
          transition: all 0.15s;
          cursor: pointer;

          @include mobile {
            width: 100%;
          }
        }

        .deleteButton {
          color: var(--white-semi);
          background-color: var(--color-expense);

          @include mobile {
            order: -1;
          }

          &:hover {
            background-color: var(--color-expense-hover);
          }
          &:active {
            background-color: var(--color-expense-active);
          }

          .spinner {
            color: white;
          }
        }
      }
    }
  }

  .newbadge {
    position: absolute;
    top: -5px;
    right: calc(-100% - 10px);
    font-size: 0.5rem;
  }
}
