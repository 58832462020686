.tooltip {
  position: relative;
  display: inline-block;

  .tooltipText {
    position: absolute;
    top: calc(-100% - 10px);
    right: 0;
    transform: translateX(25%);
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 5px;
    border-radius: 4px;
    z-index: 1;
    font-size: 0.75rem;
  }
}
