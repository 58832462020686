@import "../../index.scss";

.budget {
  .gridContainer {
    display: grid;
    grid-template-columns: 9fr 3fr;
    grid-gap: 24px;
    margin-top: 12px;

    @include tablet {
      grid-template-columns: 1fr;
    }

    .currency {
      margin-right: 0.35rem;
    }

    .left,
    .right {
      padding: 20px;
      background-color: var(--white-semi);
      border-radius: 10px;
      box-shadow: var(--box-shadow);

      @include mobile {
        padding: 10px 5px;
      }

      .title {
        width: 100%;
        font-size: 1rem;
        color: var(--font-color);
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 24px;
      }
    }

    .left {
      .optionsContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;

        .navigatePeriod {
          display: flex;
          align-items: center;

          .dropdown {
            position: relative;
            &.hidden {
              display: none;
            }
            .currentPeriod {
              margin-left: 20px;
              text-transform: capitalize;
              color: rgb(107 114 128);
              display: flex;
              align-items: center;
              position: relative;
              cursor: pointer;
              font-size: 0.875rem;

              .icon {
                font-size: 1rem;
                margin-left: 3px;
              }
            }
            .dropdownMenu {
              position: absolute;
              text-transform: capitalize;
              inset: 1.4rem auto auto 0;
              min-width: 10rem;
              overflow: hidden;
              padding: 0.5rem 0;
              background-color: #fff;
              z-index: 1000;
              border: 1px solid #eff0f2;
              border-radius: 1rem;
              box-shadow: var(--box-shadow);
              text-align: left;
              cursor: pointer;

              .menuItem {
                width: 100%;
                font-weight: 400;
                color: #545965;
                white-space: nowrap;
                padding: 0.35rem 1.5rem;

                &:hover {
                  background-color: var(--bg-color-100);
                }
              }
            }
          }
        }
      }

      .periodTitle {
        font-size: 1rem;
        line-height: 30px;
        text-transform: uppercase;
        color: var(--font-color);
        font-weight: 600;

        &.mobileOnly {
          display: none;
        }

        @include mobile {
          display: none;

          &.mobileOnly {
            display: block;
            text-align: center;
            margin-bottom: 1.25rem;
          }
        }
      }
      .buttonGroup {
        display: flex;
        align-items: center;

        button {
          text-transform: capitalize;
          display: flex;
          align-items: center;
          white-space: nowrap;
          background-color: var(--white-semi);
          color: var(--font-color);
          border: 1px solid var(--border-color);
          font-size: 0.875rem;
          line-height: 1.5;
          box-sizing: border-box;
          padding: 0.47rem 0.75rem;
          transition: all 0.15s;

          &:hover {
            background: var(--color-income-hover);
            color: var(--white-semi);
            border: 1px solid var(--color-income-hover);
          }
          &:active {
            background: var(--color-income-active);
            color: var(--white-semi);
            border: 1px solid var(--color-income-active);
            transform: scale(0.96);
          }

          &.active {
            background-color: var(--color-income-active);
            color: var(--white-semi);
            border: 1px solid var(--color-income-active);
          }

          .icon {
            font-size: 1.3125rem;
            line-height: 1;
          }

          &:first-child {
            border-top-left-radius: 0.75rem;
            border-top-right-radius: 0;
            border-bottom-left-radius: 0.75rem;
            border-bottom-right-radius: 0;
          }
          &:last-child {
            border-top-right-radius: 0.75rem;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0.75rem;
            border-bottom-left-radius: 0;
          }
        }
      }
    }

    .right {
      @include tablet {
        order: -1;
      }
    }
  }
}
