.colorpicker{
    display: grid;
    grid-template-columns: repeat(
        auto-fit, minmax(min(24px, 100%), 1fr)
        );
    align-items: center;
    box-sizing: border-box;
    gap: 5px;
    
    .colorBox{
        width: 24px;
        height: 24px;
        border-radius: 50%;
        box-sizing: border-box;
        cursor: pointer;
        
        &:hover, &.active{
            border: 2px solid black;
        }
    }
}