@import "../../index.scss";

.subscription {
  hr {
    color: #d4d4d4;
    background-color: #d4d4d4;
    height: 1px;
    border: none;
    margin-bottom: 1.5rem;
  }

  .summary {
    margin-bottom: 24px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(200px, 100%), 1fr));
    grid-gap: 24px;

    @include mobile {
      padding-inline: 10px;
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }
  }

  .subscriptionsList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(350px, 100%), 1fr));

    grid-gap: 24px;
    margin-bottom: 24px;

    @include mobile {
      padding-inline: 10px;
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }

    .createNewContainer {
      display: flex;
      align-items: center;
      box-shadow: var(--box-shadow);
      background-color: #fff;
      border-radius: 1rem;
      transition: all 0.15s;
      animation: slide-up 0.15s;
      padding: 1rem;
      max-width: 350px;
      cursor: pointer;

      .icon {
        margin-right: 10px;
        font-size: 1rem;
        color: var(--color-main);
      }
      span {
        color: rgb(107 114 128);
      }

      &:hover {
        transform: scale(1.02);

        .icon {
          color: #fff;
          background-color: var(--color-main);
          border-radius: 50%;
        }
        span {
          color: var(--color-main);
        }
      }

      &:active {
        transform: scale(0.98);
      }
    }

    .upgradeMembership {
      margin: 0 !important;
      display: flex;
      align-items: center;
      box-shadow: var(--box-shadow);
      background-color: #fff;
      border-radius: 1rem;
      transition: all 0.15s;
      animation: slide-up 0.15s;
      padding: 1rem;
      max-width: 350px;
      cursor: not-allowed;

      .icon {
        margin-right: 10px;
        font-size: 1rem;
        color: rgb(107 114 128);
      }
      span {
        color: rgb(107 114 128);
      }
    }

    .createNewSubscriptionWidget {
      box-shadow: var(--box-shadow);
      background-color: #fff;
      border-radius: 1rem;
      animation: slide-up 0.15s;
      max-width: 350px;

      input,
      textarea,
      select {
        border: none;
        border-bottom: 1px solid lightgray;
        background-color: transparent;
        width: 100%;
        padding: 5px;
      }

      .description,
      select {
        border: 1px solid lightgray;
        border-radius: 5px;
      }

      .top {
        padding: 1.25rem;

        .title {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;

          .subscriptionIconContainer {
            margin-right: 20px;

            .roundBackground {
              height: 48px;
              width: 48px;
              border-radius: 50%;
              background-color: #efefef;
              position: relative;

              img {
                object-fit: cover;
                height: 48px;
                width: 48px;
                border-radius: 50%;
                // position: absolute;
              }
            }
          }

          .inputsTop {
            margin-right: 20px;
          }
        }

        .details {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          textarea,
          input,
          select {
            width: 90%;
          }
          textarea,
          input,
          select:not(:last-child) {
            margin-bottom: 0.5rem;
          }
        }
      }

      .bottom {
        border-top: 1px solid #eff0f2;
        padding: 0.5rem 1.25rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: var(--font-color);
        font-weight: 500;
        font-size: 0.875rem;

        .row {
          width: 90%;
          display: flex;
          font-size: 0.75rem;
          align-items: center;
          margin-bottom: 0.25rem;

          label {
            white-space: nowrap;
            margin-right: 10px;
          }
        }

        .subscriptionStartDate {
          display: flex;
          align-items: center;
        }

        .note {
          font-weight: 300;
          font-size: 0.625rem;
        }

        .subscriptionAmount {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          input {
            width: 85%;
          }
          span {
            transform: translateY(-15%);
          }
        }
        input,
        select {
          width: 90%;
        }
      }

      .buttons {
        display: flex;
        justify-content: center;
        padding-bottom: 0.5rem;

        button {
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 3rem;
          width: 3rem;
          cursor: pointer;
          transition: all 0.15s;

          &:hover {
            transform: scale(1.04);
          }
          &:active {
            transform: scale(0.96);
          }
        }
        .submitButton {
          margin-right: 0.75rem;
          background-color: var(--color-main);
          color: white;

          &:hover {
            background-color: var(--color-income-hover);
          }
          &:active {
            background-color: var(--color-income-active);
          }
          .spinner {
            color: white;
          }
        }

        .cancelButton {
          color: gray;
        }
      }
    }
  }

  // .confirmDelete {
  //   position: fixed;
  //   z-index: 9999;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   background-color: rgba(0, 0, 0, 0.507);
  //   justify-content: center;
  //   align-items: center;
  //   display: flex;

  //   .confirmDeleteContainer {
  //     background-color: #fff;
  //     padding: 0 60px;
  //     border-radius: 10px;
  //     position: relative;
  //     display: flex;
  //     flex-direction: column;
  //     box-shadow: var(--box-shadow-dark);
  //     align-items: center;

  //     @include mobile {
  //       padding: 0 30px;
  //     }

  //     h1 {
  //       font-size: 20px;
  //       font-weight: 600;
  //       color: var(--font-color);
  //       text-align: center;
  //     }

  //     .row {
  //       display: flex;
  //       align-items: center;
  //       margin-bottom: 0.67em;
  //       box-sizing: border-box;
  //       gap: 1rem;

  //       Button {
  //         display: flex;
  //         align-items: center;
  //         justify-content: center;
  //         font-size: 0.875rem;
  //         line-height: 1.5;
  //         text-align: center;
  //         font-weight: 600;
  //         white-space: nowrap;
  //         border: none;
  //         padding: 0.47rem 0.75rem;
  //         border-radius: 0.75rem;
  //         transition: all 0.15s;
  //         cursor: pointer;
  //       }

  //       .deleteButton {
  //         color: var(--white-semi);
  //         background-color: #f56e6e;

  //         &:hover {
  //           background-color: #e16464;
  //         }
  //         &:active {
  //           background-color: #d05c5c;
  //         }

  //         .spinner {
  //           color: white;
  //         }
  //       }

  //       .cancelButton {
  //         background-color: #f8f9fa;
  //         color: #212529;

  //         &:hover {
  //           background-color: #e6e7e7;
  //         }
  //         &:active {
  //           background-color: #c9caca;
  //         }
  //       }
  //     }
  //   }
  // }
}
